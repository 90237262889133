import $ from "jquery";
import M from "materialize-css";
import Splide from "@splidejs/splide";

$(() => {
    let el = $('#dropdown-profile-trigger');
    M.Dropdown.init(el, {
        alignment: "right",
        outDuration: 150
    });

    // var elems = document.querySelectorAll('.carousel');
    // M.Carousel.init(elems, {
    //     fullWidth: true,
    //     indicators: true,
    //     noWrap: true
    // });
    var splide = new Splide('.silder-home', {
        type: 'loop',
        autoplay: true,
        interval: 5000,
        speed: 1000,
        perPage: 1
    });

    // splide.on( 'autoplay:playing', function ( rate ) {
    //     console.log( rate ); // 0-1
    // } );

    splide.mount();

    //---
    var sliderProjects = new Splide('.slider-projects', {
        type: 'loop',
        autoplay: true,
        interval: 5000,
        speed: 1000,
        perPage: 1,
        padding: '15%'
    });

    sliderProjects.mount();
});